import React, { Fragment, useState, useContext, useEffect } from "react";
import tw from "tailwind.macro";
import styled, { css } from "styled-components/macro";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import { AccordionContext } from "./Accordion";
import PropTypes from "prop-types";
//import Row from "../Row";
import FactSheetBlock from "../FactSheetBlock";
import Skeleton from "react-loading-skeleton";
import to from "../../lib/to";
import getListItemsById from "../../lib/api/getListItemsById";
import useListData from "../../lib/useListData";

const borderStyle = css`
  ${tw`border border-solid border-grey-400`}
`;

const HeaderTitle = styled.span`
  ${tw`block`} flex-grow:1;
`;
const HeaderButton = styled.span`
  ${tw`flex text-blue-500 text-sm font-bold items-center`}
  transition: 0.2s color, 0.2s background-color;
  svg {
    ${tw`w-6 h-6 ml-3`}
  }
`;

const HeaderStyled = styled.button`
  ${borderStyle} ${tw`flex w-full text-left items-center p-4 bg-white lg:px-6 hover:bg-grey-100`}
  transition: 0.2s color, 0.2s background-color;
  ${props =>
    props.isExpanded
      ? tw`bg-blue-500 text-white hover:bg-blue-500 hover:text-white`
      : null}

  ${HeaderButton} {
    ${props => (props.isExpanded ? tw`text-white` : null)}
  }
`;

const ItemBodyStyled = styled.div`
  ${borderStyle} ${tw`hidden border-t-0 flex-row flex-wrap lg:flex-no-wrap`}
  border-left-width: 4px;
  border-left-color: #005a96;
  ${props => (props.isExpanded ? tw`flex` : null)}
`;

const ItemChild = styled.div`
  ${tw`flex flex-wrap items-center px-4 lg:px-6 py-4 border-0 border-b border-solid border-grey-400`}
  &:last-child {
    border-bottom: none;
  }
  span {
    max-width: 100%;
    flex-grow: 1;
    @media (min-width: 1024px) {
      flex-grow: 0;
    }
  }
`;

const ItemGrandChild = styled.div`
  ${borderStyle} ${tw`border-0 border-b bg-grey-200 px-8 lg:px-12 py-3`}
`;

const ItemLink = styled.a`
  ${tw`text-blue-500 text-underline ml-3 lg:ml-6`}
`;

const AccordionItem = props => {
  const [isExpanded, setIsExpanded] = useState(props.expanded || false);
  const { toggle, externalToggle } = useContext(AccordionContext);
  //const [isLoadingItems, setIsLoadingItems] = useState(false);
  //const [isErrorItems, setIsErrorItems] = useState(false);
  //const [isLoadingFactSheet, setIsLoadingFactSheet] = useState(false);
  //const [isErrorFactSheet, setIsErrorFactSheet] = useState(false);
  //const [itemsData, setItemsData] = useState();
  //const [factSheetData, setFactSheetData] = useState();

  const factSheet = useListData(
    () => {
      return getListItemsById("fact sheets", {
        id: props.FactSheetId,
        select: [`Title`, `PDF`, `Page`]
      });
    },
    {},
    { loadOnMount: false }
  );

  const itemsData = useListData(
    async () => {
      const data = await getListItemsById("browse all items", {
        id: props.ItemsId,
        select: [`Title`, `ChildrenId`, `Page`]
      });
      const d = await Promise.all(
        data.d.results.map(async result => {
          result.Children = null;
          if (!result.ChildrenId.results.length) return result;
          const [err, data] = await to(
            getListItemsById("browse all items", {
              id: result.ChildrenId.results,
              select: [`Id`, `Title`, `Page`]
            })
          );
          //Fail silently. missing data. oh well.
          if (err) return result;
          result.Children = data.d.results.map(r => r);
          return result;
        })
      );
      let _data = data;
      _data.d.results = d;
      return _data;
    },
    {},
    { loadOnMount: false }
  );

  useEffect(() => {
    externalToggle(props.itemIndex, activeIndex => {
      if (activeIndex !== props.itemIndex) setIsExpanded(false);
    });
    return () => {
      externalToggle(props.itemIndex);
    };
  }, []);

  useEffect(() => {
    toggle(props.itemIndex, isExpanded);
    if (!itemsData.isLoading && isExpanded && !itemsData.data) {
      itemsData.loadData();
    }
    /*if (!isLoadingFactSheet && isExpanded && !factSheetData) {
      fetchFactSheetData();
    }*/
    if (!factSheet.isLoading && isExpanded && !factSheet.data) {
      factSheet.loadData();
    }
  }, [isExpanded]);

  const toggleExpanded = () => {
    if (props.isLoading) return;
    setIsExpanded(!isExpanded);
  };

  return (
    <div css={tw`mb-4`} className={props.className}>
      <HeaderStyled isExpanded={isExpanded} onClick={toggleExpanded}>
        <HeaderTitle>
          {props.isLoading ? (
            <span css={tw`block max-w-xs`}>
              <Skeleton height={22} />
            </span>
          ) : (
            props.Title
          )}
        </HeaderTitle>
        <HeaderButton>
          {isExpanded ? (
            <>
              Collapse <MdRemoveCircleOutline />
            </>
          ) : (
            <>
              Expand <MdAddCircleOutline />
            </>
          )}
        </HeaderButton>
      </HeaderStyled>
      <ItemBodyStyled isExpanded={isExpanded}>
        <FactSheetBlock
          css={tw`bg-white lg:order-last border-t-0 border-b lg:border-b-0 w-full lg:w-1/4`}
          isLoading={factSheet.isLoading}
          factSheets={factSheet.data}
        />
        <div
          css={`
            flex-grow: 1;
            ${tw`bg-grey-100 w-full lg:w-3/4`}
          `}
        >
          {itemsData.isLoading ? (
            <ItemChild>
              <div css={tw`block mb-3 w-full`}>
                <Skeleton height={22} />
              </div>
              <div css={tw`block mb-3 max-w-xs w-full`}>
                <Skeleton height={22} />
              </div>
              <div css={tw`block mb-3 max-w-sm w-full`}>
                <Skeleton height={22} />
              </div>
            </ItemChild>
          ) : null}
          {!itemsData.isLoading && itemsData.data
            ? itemsData.data.map((item, index) => (
                <Fragment key={index}>
                  <ItemChild>
                    <span>{item.Title}</span>
                    {item.Page?.Url ? (
                      <ItemLink
                        href={item.Page.Url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </ItemLink>
                    ) : null}
                  </ItemChild>
                  {item.Children
                    ? item.Children.map((child, gindex) => (
                        <ItemGrandChild key={gindex}>
                          {child.Title}
                        </ItemGrandChild>
                      ))
                    : null}
                </Fragment>
              ))
            : null}
          {!itemsData.isLoading && itemsData.isError ? (
            <ItemChild>
              <strong>There was an error getting this information.</strong>
            </ItemChild>
          ) : null}
        </div>
      </ItemBodyStyled>
    </div>
  );
};
AccordionItem.propTypes = {
  itemIndex: PropTypes.number.isRequired
};
export default AccordionItem;
