import styled from "styled-components/macro";
import tw from "tailwind.macro";

const FormText = styled.span`
  ${tw`text-lg block text-gray-700`}
  ${props => (props.isError ? tw`text-red-600` : null)}
  ${props => (props.isSuccess ? tw`text-teal-400` : null)}
  ${props => (props.isHidden ? tw`hidden` : null)}
`;
export default FormText;

const FormError = styled(FormText)`
  ${tw`text-red-600`}
`;
export { FormError };

const FormHelp = styled(FormText)`
  ${tw`italic`}
`;
export { FormHelp };
