import React from "react";
import Layout from "../Components/Layout";
import Container from "../Components/Container";
import Button from "../Components/Button";
import { MdChevronLeft } from "react-icons/md";
import { Link } from "react-router-dom";
import tw from "tailwind.macro";
import "styled-components/macro";
import { Accordion, AccordionItem } from "../Components/Accordion";
import getList from "../lib/api/getList";
import useListData from "../lib/useListData";

const BrowsePage = props => {
  const browseAll = useListData(() => {
    return getList("browse all", {
      select: [`Id`, `Title`, `ItemsId`, `Fact_x0020_sheetId`]
    }).then(data => {
      data.d = data.d || { results: null };
      data.d.results = data.d.results?.map(result => {
        return {
          Title: result.Title,
          ItemsId: result.ItemsId?.results,
          FactSheetId: result.Fact_x0020_sheetId?.results,
          Id: result.Id
        };
      });
      return data;
    });
  });

  return (
    <Layout pageTitle="Browse all">
      <Container css={tw`py-20`}>
        <div css={tw`mb-12`}>
          <Button icon={<MdChevronLeft />} as={Link} to="/">
            Back
          </Button>
        </div>
        {!browseAll.isError ? (
          <Accordion mode="single">
            {browseAll.isLoading ? (
              <>
                <AccordionItem isLoading={true} itemIndex={1} />
                <AccordionItem isLoading={true} itemIndex={2} />
                <AccordionItem isLoading={true} itemIndex={3} />
                <AccordionItem isLoading={true} itemIndex={4} />
                <AccordionItem isLoading={true} itemIndex={5} />
              </>
            ) : null}
            {browseAll.data?.length
              ? browseAll.data.map((item, index) => (
                  <AccordionItem {...item} itemIndex={index} key={index} />
                ))
              : null}
          </Accordion>
        ) : (
          <p>Something went wrong.</p>
        )}
      </Container>
    </Layout>
  );
};

export default BrowsePage;
