const removeIds = html => {
  if (!html) return html;
  return html.replace(/id="(.*?)"/g, "");
};
const removeInlineStyles = html => {
  if (!html) return html;
  return html.replace(/style="(.*?)"/g, "");
};
const removeClassNames = html => {
  if (!html) return html;
  return html.replace(/class="(.*?)"/g, "");
};
const makeHtmlNice = html => {
  if (!html) return html;
  try {
    html = decodeURIComponent(html);
  } catch (e) {}

  html = removeClassNames(html);
  html = removeInlineStyles(html);
  html = removeIds(html);

  return html;
};

const stripTags = html => {
  const div = document.createElement("div");
  div.innerHTML = html;
  let text = div.textContent || div.innerText || "";

  text = text.replace(/[^\x20-\x7E]/g, "");
  return text;
};

export {
  removeClassNames,
  removeInlineStyles,
  removeIds,
  makeHtmlNice,
  stripTags
};
