//Support IE 11
//import "core-js/es";
//import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/map";
import "mutation-observer";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

//Remove a shit stylesheet if necessary
(function() {
  for (var i = 0; i < document.styleSheets.length; i++) {
    if (!document.styleSheets[i].href) continue;
    if (document.styleSheets[i].href.search(/corev15\.css/) !== -1) {
      document.styleSheets[i].disabled = true;
    }
  }
})();

ReactDOM.render(<App />, document.getElementById("root"));
