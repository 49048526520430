import React from "react";
import Layout from "../Components/Layout";

const NotFoundPage = props => {
  return (
    <Layout pageTitle="Not found">
      <p>Looks like the content you wanted isn't here.</p>
    </Layout>
  );
};
export default NotFoundPage;
