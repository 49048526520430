import React from "react";
import "styled-components/macro";
import tw from "tailwind.macro";

import Container from "../../Components/Container";
import { Label, FormHelp } from "../../Components/Form";
import { ControlCheckbox } from "../../Components/Form/Control";
import Row from "../../Components/Row";
import Col from "../../Components/Col";
import Button from "../../Components/Button";
import ControlList from "../../Components/ControlList";

const Checkup2 = ({ state, prevStep, nextStep, setState, BackButton }) => {
  const currentHasInsuranceFieldKey = (() => {
    if (state.type === "renter") return "contents";
    if (state.type === "business") return "business";
    if (state.type === "landlord") return "rental";
    return null;
  })();
  const currentHasInsuranceFieldValue = (() => {
    if (currentHasInsuranceFieldKey)
      return state.hasInsurance[currentHasInsuranceFieldKey];
  })();

  const onContinue = () => {
    nextStep();
  };

  const isContinueDisabled = () => {
    if (state.type === "home") return false;
    return currentHasInsuranceFieldValue === null;
  };

  const handleClickYesNo = isYes => {
    return () => {
      let newState = state.hasInsurance;
      newState[currentHasInsuranceFieldKey] = isYes;
      setState({ hasInsurance: newState });
    };
  };

  const isActiveYes = () => {
    return currentHasInsuranceFieldValue === true;
  };
  const isActiveNo = () => {
    return currentHasInsuranceFieldValue === false;
  };

  const handleListChange = e => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    let newState = { ...state.hasInsurance };
    newState[item] = isChecked;
    setState({ hasInsurance: newState });
  };

  const listItems = [
    <ControlCheckbox
      id="insurance['home']"
      value="home"
      label="Home"
      name="insurance"
      onChange={handleListChange}
      checked={state.hasInsurance.home}
    />,
    <ControlCheckbox
      id="insurance['contents']"
      value="contents"
      label="Contents"
      name="insurance"
      onChange={handleListChange}
      checked={state.hasInsurance.contents}
    />
  ];

  const renderLabel = () => {
    if (state.type === "home") return ":";
    if (state.type === "renter")
      return (
        <>
          {" "}
          <strong>contents?</strong>
        </>
      );
    if (state.type === "business")
      return (
        <>
          {" "}
          <strong>business?</strong>
        </>
      );
    if (state.type === "landlord")
      return (
        <>
          {" "}
          <strong>rental property/properties?</strong>
        </>
      );
    return null;
  };

  return (
    <section css={tw`py-8 lg:py-16 xl:py-20`}>
      <Container>
        <Label as="span">
          Do you currently have insurance for your{renderLabel()}
        </Label>
        {state.type === "home" ? (
          <>
            <FormHelp css={tw`mt-3 mb-6`}>
              Choose more than one if applicable
            </FormHelp>
            <ControlList items={listItems} />
          </>
        ) : (
          <Row css={tw`mt-6`}>
            <Col>
              <Button
                outline
                secondary
                css={tw`px-6`}
                onClick={handleClickYesNo(true)}
                active={isActiveYes()}
              >
                Yes
              </Button>
            </Col>
            <Col>
              <Button
                outline
                dark
                css={tw`px-6`}
                onClick={handleClickYesNo(false)}
                active={isActiveNo()}
              >
                No
              </Button>
            </Col>
          </Row>
        )}
        <Row css={tw`mt-20`}>
          <Col>
            <BackButton />
          </Col>
          <Col>
            <Button
              primary
              css={tw`px-6`}
              onClick={onContinue}
              disabled={isContinueDisabled()}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Checkup2;
