import React, { useState, useContext, useEffect, useRef } from "react";
import Layout from "../../Components/Layout";
import StoreContext from "../../store/StoreContext";
import tw from "tailwind.macro";
import "styled-components/macro";

import Button from "../../Components/Button";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import Col from "../../Components/Col";
import { MdPrint, MdChevronRight } from "react-icons/md";
import { AiOutlineReload } from "react-icons/ai";
import { ChecklistTable, ChecklistTableItem } from "./_table";

import getList from "../../lib/api/getList";

import to from "../../lib/to";

import mapChecklist from "../../lib/mapChecklist";
import Skeleton from "react-loading-skeleton";
import getListItemsById from "../../lib/api/getListItemsById";
import { withRouter } from "react-router";
import viewport, { scrollTop } from "../../lib/viewport";
import { Link } from "react-router-dom";

import scrollTo from "../../lib/scrollTo";
import { MdChevronLeft } from "react-icons/md";

import { makeHtmlNice } from "../../lib/html";

const ChecklistPage = withRouter(({ match, history }) => {
  const storeCtx = useContext(StoreContext);
  const checkupResult = storeCtx.get("checkup");

  if (!checkupResult) {
    history.push("/checkup");
  }
  const checklistNumber = checkupResult ? mapChecklist(checkupResult) : false;
  //const checklistNumber = 1;

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [listData, setListData] = useState(null);
  const [factSheets, setFactSheets] = useState(null);

  const buttonBarRef = useRef();
  //const activeStepRef = useRef();

  const isInViewport = el => {
    const { w: viewportWidth, h: viewportHeight } = viewport();

    //const elViewportOffset = el.getBoundingClientRect();

    //const top = elViewportOffset.top;
    //console.log({ viewportHeight, elTop: top, scrollTop: scrollTop() });
    if (viewportWidth >= 1024) {
      //console.log(buttonBarRef.current.getBoundingClientRect(), scrollTop());
      const { y, height } = buttonBarRef.current.getBoundingClientRect();
      return y + height - (viewportHeight - scrollTop());
    } else {
      const { y, height } = el.getBoundingClientRect();
      return y + height - (viewportHeight - scrollTop());
    }
  };

  const maybeScrollIntoView = el => {
    if (viewport().w >= 1024) {
      const distance = isInViewport(el);
      if (distance > 0) {
        scrollTo(scrollTop() + distance + 30, 600);
      }
    } else {
      const { y } = el.getBoundingClientRect();
      scrollTo(scrollTop() + y, 600);
    }
  };

  const handleRef = index => {
    return r => {
      if (index === activeStep && r && activeStep !== 0) {
        maybeScrollIntoView(r);
        r.focus();
        //activeStepRef.current = r;
      }
    };
  };

  /*useEffect(() => {
    if (activeStep === 0 || activeStep === listData.length) return;
    
  }, [activeStep]);*/

  const retry = () => {
    setIsLoading(true);
    loadChecklistData();
  };

  const loadChecklistData = async () => {
    const [err, data] = await to(
      getList("checklists", {
        filter: `Title eq ${checklistNumber}`,
        select: ["ItemsId"]
      })
    );
    if (err) {
      setIsLoading(false);
      setIsError(true);
      console.log("failed", err);
      return;
    }
    if (!data?.d?.results?.length) {
      setIsLoading(false);
      setIsError(true);
      console.log("no list!");
      return;
    }
    const itemIds = data.d.results[0].ItemsId.results;

    const [itemsErr, itemsData] = await to(
      getListItemsById("checklist items", {
        id: itemIds,
        select: ["Content", "Fact_x0020_sheetId"]
      })
    );

    setIsLoading(false);
    if (itemsErr) {
      setIsError(true);
      console.log("failed", itemsErr);
      return;
    }
    setIsError(false);

    setListData(
      itemsData.d.results.map(result => {
        result.factSheetIds = result["Fact_x0020_sheetId"].results.map(
          fsid => fsid
        );
        return {
          Content: makeHtmlNice(result.Content),
          factSheetsIds: result.factSheetIds,
          factSheets: "loading"
        };
      })
    );

    //Get fact sheets
    const factSheetIds = [].concat(
      ...itemsData.d.results.map(result => result.factSheetIds)
    );

    const [fsErr, fsData] = await to(
      getListItemsById("fact sheets", {
        id: factSheetIds,
        select: [`Id`, `Title`, `Page`, `PDF`]
      })
    );
    if (fsErr) {
      console.log(fsErr);
      return;
    }
    const factSheets = fsData.d.results;
    setFactSheets(factSheets);
  };

  useEffect(() => {
    if (!listData || !factSheets) return;
    let newItemsData = listData.map(item => {
      if (!item.factSheetsIds) return item;
      item.factSheets = item.factSheetsIds.map(fsId => {
        const fs = factSheets.find(factSheet => factSheet.Id === fsId);
        return fs;
      });
      return item;
    });
    setListData(newItemsData);
  }, [factSheets]);

  useEffect(() => {
    const loadAsyncEffect = async () => {
      await loadChecklistData();
    };
    if (checklistNumber) loadAsyncEffect();
    else {
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  const numSteps = listData?.length;

  const onNextStep = () => {
    if (activeStep < numSteps) setActiveStep(activeStep + 1);
  };

  const isStepActive = stepIndex => {
    if (activeStep === numSteps) return null;
    return stepIndex === activeStep;
  };

  return (
    <Layout pageTitle="Insurance Checkup">
      {/*<Button onClick={() => setIsLoading(!isLoading)}>Toggle loading</Button>
      <Button onClick={() => setIsError(!isError)}>Toggle error</Button>*/}
      <Container css={tw`pt-20 print:pt-0`}>
        <div css={tw`mb-12 print:hidden`}>
          <Button icon={<MdChevronLeft />} as={Link} to="/">
            Back
          </Button>
        </div>
        <Row itemsCenter css={tw`mb-8 print:mb-2`}>
          <Col grow css={tw`mb-4`}>
            <span css={tw`block text-4xl font-hairline`}>
              {isLoading ? (
                <Skeleton width={220} height={50} />
              ) : (
                `Your Checklist`
              )}
            </span>
            <div css={tw`hidden print:block pt-4`}>
              <p>
                You can find these fact sheets and more at
                InsuranceCheckup.tas.gov.au
              </p>
            </div>
          </Col>
          <Col css={tw`text-right w-full lg:w-auto print:hidden`}>
            <Button
              icon={<MdPrint />}
              disabled={isLoading || isError}
              onClick={() => window.print()}
            >
              Print my checklist
            </Button>
          </Col>
        </Row>
        {isError ? (
          <div css={tw`border border-solid border-grey-400 p-4`}>
            There was an error fetching the checklist data.
          </div>
        ) : (
          <ChecklistTable
            title={
              isLoading ? <Skeleton width={100} height={25} /> : `Checklist`
            }
            css={tw`mb-12`}
          >
            {isLoading || !listData?.length ? (
              <>
                <ChecklistTableItem loading={true} active={true} />
                <ChecklistTableItem loading={true} active={false} />
              </>
            ) : (
              listData.map((listItem, listItemIndex) => (
                <ChecklistTableItem
                  ref={handleRef(listItemIndex)}
                  key={listItemIndex}
                  active={isStepActive(listItemIndex)}
                  fadeOut={
                    listItemIndex > 0 && activeStep === listItemIndex - 1
                  }
                  hidden={listItemIndex > 0 && activeStep < listItemIndex - 1}
                  factSheets={listItem.factSheets}
                  factSheetIds={listItem.factSheetsIds}
                  index={listItemIndex}
                  listLength={listData.length}
                  activeStep={activeStep}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: listItem.Content
                    }}
                  />
                </ChecklistTableItem>
              ))
            )}
          </ChecklistTable>
        )}
      </Container>
      {!listData || activeStep < numSteps ? (
        <Container
          css={tw`fixed lg:relative bottom-0 left-0 w-full z-20 bg-white py-3 print:hidden`}
          ref={buttonBarRef}
        >
          <Row>
            <Col w="1/2">
              {isError ? (
                <Button
                  outline
                  secondary
                  onClick={retry}
                  disabled={isLoading}
                  iconAfter={<AiOutlineReload />}
                  loading={isLoading}
                >
                  Try again
                </Button>
              ) : (
                <Button
                  secondary
                  iconAfter={<MdChevronRight />}
                  onClick={onNextStep}
                  disabled={isLoading || isError}
                >
                  Next step
                </Button>
              )}
            </Col>
            <Col w="1/2" css={tw`text-right`}>
              <Button
                primary
                outline
                outline-solid
                onClick={() => setActiveStep(numSteps)}
                disabled={isLoading || isError}
              >
                View all steps
              </Button>
            </Col>
          </Row>
        </Container>
      ) : null}
      <Container css={tw`py-3 pt-12 pb-20 print:hidden`}>
        <Button as={Link} to="/">
          Return to start
        </Button>
      </Container>
    </Layout>
  );
});
export default ChecklistPage;
