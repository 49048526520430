import React from "react";
import Row from "../../Components/Row";
import Col from "../../Components/Col";
import CaseStudyThumb from "../../Components/CaseStudyThumb";
import Skeleton from "react-loading-skeleton";
import tw from "tailwind.macro";
import "styled-components/macro";
import Copy from "../../Components/Copy";
import { makeHtmlNice } from "../../lib/html";
import useListData from "../../lib/useListData";

const CaseStudies = props => {
  const homeCaseStudyContent = useListData(
    "fields",
    {
      select: [`Value`],
      filter: `Title eq Home case study content`
    },
    { select: 0 }
  );

  const caseStudies = useListData("case studies", {
    select: ["Title,Image,Icon,Page"]
  });

  const renderCaseStudies = () => {
    if (caseStudies.isLoading)
      return (
        <Row>
          <Col w="1" md="1/2">
            <CaseStudyThumb isLoading={true} />
          </Col>
          <Col w="1" md="1/2">
            <CaseStudyThumb isLoading={true} />
          </Col>
        </Row>
      );

    if (caseStudies.isError || !caseStudies.data?.length) return null;

    return (
      <Row>
        {caseStudies.data.map((caseStudy, index) => (
          <Col w="1" md="1/2" key={index}>
            <CaseStudyThumb
              text={caseStudy.Title}
              href={caseStudy?.Page?.Url}
              src={caseStudy?.Image?.Url}
              icon={caseStudy?.Icon}
            />
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Row>
      <Col w="1" lg="1/3" css={tw`mb-6 lg:mb-0`}>
        <span css={tw`block text-2xl mb-8`}>View our Case Studies</span>
        {homeCaseStudyContent.isError ? (
          <div css={tw`p-4`}>
            <p>There was an error loading this data.</p>
          </div>
        ) : null}
        {homeCaseStudyContent.isLoading ? (
          <div
            css={`
              .react-loading-skeleton {
                ${tw`mb-3`}
              }
            `}
          >
            <Skeleton count={5} height={24} />
          </div>
        ) : null}
        {!homeCaseStudyContent.isLoading && !homeCaseStudyContent.isError ? (
          <Copy
            dangerouslySetInnerHTML={{
              __html: makeHtmlNice(homeCaseStudyContent.data?.Value)
            }}
          />
        ) : null}
      </Col>
      <Col w="1" lg="2/3">
        {renderCaseStudies()}
      </Col>
    </Row>
  );
};
export default CaseStudies;
