import React from "react";
import PropTypes from "prop-types";
import "styled-components/macro";
import tw from "tailwind.macro";
import Container from "./Container";

const PageHeader = ({ title }) => {
  return (
    <div
      css={`
        /*height: 230px;*/
        background-color: #283f64;
        padding: 20px;
        /*background-image: url(/tasalert-background-img.png);*/
        background-size: auto 100%;
        background-position: 20% top;
        background-repeat: no-repeat;
        ${tw`print:hidden`}
      `}
    >
      <Container css={tw``}>
        <div css={tw`flex items-end`}>
          <h1
            css={`
              ${tw`text-4xl lg:text-5xl text-white font-thin lg:font-hairline my-4`}
            `}
          >
            {title}
          </h1>
        </div>
      </Container>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string
};
PageHeader.defaultProps = {
  title: "Page title"
};

export default PageHeader;
