import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";

import tw from "tailwind.macro";
import "styled-components/macro";

import Container from "../../Components/Container";
import Row from "../../Components/Row";
import Col from "../../Components/Col";

import Button from "../../Components/Button";

import { Link } from "react-router-dom";

//import YouTube from "react-youtube";
import ReactPlayer from "react-player";

import Copy from "../../Components/Copy";
import CaseStudies from "./_caseStudies";
import Skeleton from "react-loading-skeleton";

import useListData from "../../lib/useListData";
import { makeHtmlNice, stripTags } from "../../lib/html";

const ResponsiveVideoWrapper = React.forwardRef((props, ref) => {
  return (
    <div
      css={`
        padding-bottom: 56.25%;
        ${tw`relative`}

        & > div {
          ${tw`absolute`}
        }
      `}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

const IndexPage = props => {
  const [section1Data, setSection1Data] = useState();
  const section1 = useListData("fields", {
    select: [`Value`, `Title`],
    filter: `Title eq Home section 1 or Title eq Home video or Title eq Home section 2`
  });

  useEffect(() => {
    setSection1Data({
      content: section1.data?.find(
        field => field?.Title?.toLowerCase() === `home section 1`
      )?.Value,
      video: section1.data?.find(
        field => field?.Title?.toLowerCase() === `home video`
      )?.Value,
      section2: section1.data?.find(
        field => field?.Title?.toLowerCase() === `home section 2`
      )?.Value
    });
  }, [section1.data]);

  return (
    <Layout pageTitle="Insurance Checkup">
      <div css={tw`bg-grey-100 py-8 lg:py-12`}>
        <Container>
          <Row itemsCenter>
            <Col w="1" lg="auto">
              <span
                css={tw`font-bold text-xl lg:text-2xl text-center lg:text-left block my-4`}
              >
                Find the fact sheets most relevant to me
              </span>
            </Col>
            <Col w="1" lg="auto">
              <div css={tw`text-center lg:text-left`}>
                <Button
                  size="lg"
                  size-lg="xl"
                  secondary
                  as={Link}
                  to="/checkup"
                >
                  Start the Insurance Checkup
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section css={tw`py-8 lg:py-16 xl:py-20`}>
        <Container>
          <Row css={tw`mt-4`}>
            <Col w="1" lg="1/2" css={tw`mb-4`}>
              {section1.isError ? (
                <div css={tw`p-4`}>
                  <p>There was an error loading this data.</p>
                </div>
              ) : null}
              {section1.isLoading ? (
                <div
                  css={`
                    .react-loading-skeleton {
                      ${tw`mb-3`}
                    }
                  `}
                >
                  <Skeleton count={5} height={24} />
                </div>
              ) : null}
              {!section1.isLoading &&
              !section1.isError &&
              section1Data?.content ? (
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: makeHtmlNice(section1Data.content)
                  }}
                />
              ) : null}
            </Col>
            <Col w="1" lg="1/2" css={tw`mb-4`}>
              {!section1.isLoading &&
              !section1.isError &&
              section1Data?.video ? (
                <div css={tw`md:px-8 lg:px-0 lg:pl-12`}>
                  {/*<YouTube
                  videoId="C-3Lhj66RW4"
                  opts={{ width: 640, height: 390 }}
                  css={tw`w-full`}
                />*/}
                  <ReactPlayer
                    url={stripTags(section1Data.video)}
                    controls={true}
                    wrapper={ResponsiveVideoWrapper}
                  />
                </div>
              ) : null}
            </Col>
          </Row>

          {/*!section1.isLoading &&
          !section1.isError &&
          section1Data?.section2 ? (
            <div
              css={`
                ${tw`max-w-3xl border border-solid border-grey-300 px-4 py-6`} border-left: 8px solid #651149;
              `}
            >
              <Copy
                dangerouslySetInnerHTML={{
                  __html: makeHtmlNice(section1Data.section2)
                }}
              />
            </div>
          ) : null*/}
        </Container>
      </section>

      <section css={tw`bg-grey-100 py-8 lg:py-16 xl:pt-20`}>
        <Container>
          <div css={tw`max-w-3xl mx-auto`}>
            <span css={tw`block text-xl mb-4`}>
              Preparing and planning your insurance for a natural disaster
            </span>
            <span css={tw`block font-bold mb-3`}>I would like to:</span>
            <Row css={tw`pb-6`}>
              <Col w="1" md="1/2" css={tw`mb-3`}>
                <Button block primary css={tw`py-6`} tag={Link} to="/checkup">
                  <span css={tw`block`}>
                    <span css={tw`block w-full text-2xl mb-3`}>
                      Start the{" "}
                      <span css={tw`font-bold`}>Insurance Checkup</span>
                    </span>
                    <span>Find the fact sheets most relevant to me</span>
                  </span>
                </Button>
              </Col>
              <Col w="1" md="1/2" css={tw`mb-3`}>
                <Button
                  block
                  primary
                  outline
                  outline-solid
                  css={tw`py-6`}
                  tag={Link}
                  to="/browse"
                >
                  <span css={tw`block`}>
                    <span css={tw`block w-full text-2xl mb-3`}>
                      <span css={tw`font-bold`}>Browse</span> all
                    </span>
                    <span>Browse all the insurance fact sheets</span>
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section css={tw`py-8 lg:py-16`}>
        <Container>
          <CaseStudies />
        </Container>
      </section>
    </Layout>
  );
};
export default IndexPage;
