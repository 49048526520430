import React, { useState, useContext } from "react";
import Layout from "../../Components/Layout";
import StoreContext from "../../store/StoreContext";
import { withRouter } from "react-router";
import tw from "tailwind.macro";
import "styled-components/macro";

//import { Route } from "react-router";

import Checkup1 from "./_1-start";
import Checkup2 from "./_2";
import Checkup3 from "./_3";

import Button from "../../Components/Button";

import IconsPng from "../../images/icons.png";
import Container from "../../Components/Container";
import { sendEvent, setCustom } from "../../lib/analytics";
import mapChecklist from "../../lib/mapChecklist";

const CheckupPage = withRouter(({ history, match }) => {
  const storeCtx = useContext(StoreContext);

  const initialState = {
    type: null,
    hasInsurance: {
      home: null,
      contents: null,
      business: null,
      rental: null
    },
    isRural: null,
    postcode: null
  };
  const [formStep, setFormStep] = useState(0);
  const [formState, setFormState] = useState(initialState);

  const steps = [
    {
      component: Checkup1
    },
    {
      component: Checkup2
    },
    {
      component: Checkup3
    }
  ];

  //Functions to pass to steps
  const setState = newState => {
    if (newState.type && newState.type !== formState.type)
      newState.hasInsurance = initialState.hasInsurance;
    setFormState({ ...formState, ...newState });
  };

  const nextStep = () => {
    if (formStep < steps.length - 1) {
      sendEvent({
        eventCategory: "Checkup",
        eventAction: "step complete",
        eventLabel: formStep + 1 //1 based
      });
      setFormStep(formStep + 1);
    } else {
      //Complete
      //console.log("complete");
      storeCtx.set("checkup", formState);
      const checklistNumber = mapChecklist(formState);

      const dimensionName =
        window?.checkup_app_data?.post_code_dimension || "dimension1";
      const customData = {};
      customData[dimensionName] = formState.postcode;
      setCustom(customData, "post_code");
      sendEvent({
        eventCategory: "Checkup",
        eventAction: "scenario selected",
        eventLabel: checklistNumber
      });
      history.push("/checklist");
    }
  };
  const prevStep = () => {
    if (formStep > 0) setFormStep(formStep - 1);
  };
  const BackButton = () => {
    return <Button onClick={prevStep}>Back</Button>;
  };

  return (
    <Layout pageTitle="Insurance Checkup">
      <div
        css={`
          top: 220px;
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
          ${tw`absolute  pointer-events-none w-full`}
        `}
      >
        <Container>
          <div
            css={`
              width: 320px;
              height: 278px;
              background-size: 100%;
              ${tw` bg-no-repeat pointer-events-none ml-auto`}
            `}
            style={{ backgroundImage: `url(${IconsPng})` }}
          />
        </Container>
      </div>
      <div css={tw`relative z-10`}>
        {formStep < steps.length
          ? steps[formStep].component({
              state: formState,
              setState,
              nextStep,
              prevStep,
              stepIndex: formStep,
              BackButton
            })
          : null}
      </div>
    </Layout>
  );
});
export default CheckupPage;
