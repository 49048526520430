import React from "react";
import PropTypes from "prop-types";
import SiteHeader from "./SiteHeader";
import PageHeader from "./PageHeader";

import tw from "tailwind.macro";
import styled from "styled-components/macro";

const AppBody = styled.div`
  ${tw``}
`;

const Layout = ({ pageTitle, children }) => {
  return (
    <>
      {process.env.NODE_ENV === `development` ? <SiteHeader /> : null}
      <PageHeader title={pageTitle} />
      <AppBody>{children}</AppBody>
    </>
  );
};
Layout.propTypes = {
  pageTitle: PropTypes.string.isRequired
};
export default Layout;
