import React from "react";
import tw from "tailwind.macro";
import "styled-components/macro";
import Skeleton from "react-loading-skeleton";

import IconFire from "../images/icon_fire.png";
import IconAlert from "../images/icon_alert.png";
import IconFile from "../images/icon_file.png";
import IconHouse from "../images/icon_house.png";
import IconBusiness from "../images/icon_business.png";
import IconStorm from "../images/icon_storm.png";
import IconRural from "../images/icon_rural.png";

const iconData = {
  fire: {
    src: IconFire,
    fill: `#4F667F`,
    fillAlt: `#344D68`
  },
  alert: {
    src: IconAlert,
    fill: `#283F64`,
    fillAlt: `#4375B9`
  },
  document: {
    src: IconFile,
    fill: `#651149`,
    fillAlt: `#40002A`
  },
  house: {
    src: IconHouse,
    fill: `#4375B9`,
    fillAlt: `#23527C`
  },
  business: {
    src: IconBusiness,
    fill: `#283F64`,
    fillAlt: `#4375B9`
  },
  storm: {
    src: IconStorm,
    fill: `#651149`,
    fillAlt: `#40002A`
  },
  rural: {
    src: IconRural,
    fill: `#4375B9`,
    fillAlt: `#23527C`
  }
};

const CaseStudyThumb = ({ href, src, text, isLoading, icon }) => {
  const getIcon = () => {
    if (!icon?.length) return null;
    if (typeof iconData[icon.toLowerCase()] === "undefined") return null;
    return iconData[icon.toLowerCase()];
  };

  return (
    <a
      href={isLoading ? null : href}
      css={tw`block relative mb-4`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span
        css={`
          ${tw`relative block overflow-hidden w-full`} padding-bottom: 50%;
          .react-loading-skeleton {
            ${tw`absolute w-full h-full left-0 top-0`}
          }
        `}
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <span
            css={tw`absolute w-full h-full left-0 top-0 bg-gray-100 block bg-no-repeat bg-cover`}
            style={{
              backgroundImage: `url(${src})`
            }}
          />
        )}
      </span>
      <span
        css={tw`bg-blue-800 text-white text-center block py-3`}
        style={{ backgroundColor: getIcon() ? getIcon().fillAlt : null }}
      >
        {isLoading ? <Skeleton width={100} height={22} /> : text}
      </span>
      {getIcon() ? (
        <span
          css={`
            ${tw`absolute top-0 left-0`}
            &:after {
              ${tw`absolute top-0 left-0`}
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-top: 7rem solid ${getIcon().fill};
              border-right: 7rem solid transparent;
            }
          `}
        >
          <span
            css={tw`w-16 h-16 block relative z-10`}
            style={{
              backgroundImage: `url(${getIcon().src})`,
              backgroundSize: `100% 100%`
            }}
          />
        </span>
      ) : null}
    </a>
  );
};
export default CaseStudyThumb;
