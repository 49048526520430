import React from "react";
import "./index.css";

import { withStore } from "./store";

import { Route, Switch } from "react-router";
import { createHashHistory } from "history";
import { Router } from "react-router-dom";

//Pages
import IndexPage from "./Pages/index";
import CheckupPage from "./Pages/checkup";
import ChecklistPage from "./Pages/checklist";
import BrowsePage from "./Pages/browse";
import NotFoundPage from "./Pages/404";
import { sendPageView } from "./lib/analytics";

const history = createHashHistory({
  hashType: "slash"
});

history.listen((location, action) => {
  sendPageView(location);
});

const storeInitialState = {
  checkup: null
};
const storeConfig = {};

const App = () => {
  return (
    <Router path="/" history={history}>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route path="/checkup" component={CheckupPage} />
        <Route path="/checklist" component={ChecklistPage} />
        <Route path="/browse" component={BrowsePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default withStore(App, storeInitialState, storeConfig);
