import React from "react";
import styled from "styled-components/macro";
import tw from "tailwind.macro";
import { useState } from "react";

export const ControlWrapStyled = styled.div`
  ${tw`inline-block relative`}
  ${props => (props.block ? tw`block` : null)}
`;
const ControlInputStyled = styled.input`
  ${tw`absolute cursor-pointer h-6 w-6`}
  margin: 0 !important;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  &:focus {
    outline: none !important;
  }
`;
const ControlInputLabel = styled.label`
  ${tw`cursor-pointer pr-4 py-3 pl-16 block  m-0 font-normal border border-solid border-grey-400 border-b-2`}

  &:before, &:after {
    ${tw`w-6 h-6 block absolute z-10 border border-solid bg-no-repeat bg-white border-grey-400`}
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    box-sizing: border-box;
  }
  ${`${ControlInputStyled}[type="radio"]`} + & {
    &:before,
    &:after {
      ${tw`rounded-full`}
    }
  }

  &:after {
    ${tw`z-20`}
  }

  ${ControlInputStyled}:checked + &:after {
    ${tw`bg-green-500 border-green-500`}
  }

  ${ControlInputStyled}:checked[type="checkbox"] + &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23ffffff' d='M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z'/%3E%3C/svg%3E");
  };
  ${ControlInputStyled}:checked + & {
    ${tw`border-green-500`}
  }
  ${ControlInputStyled}:checked[type="radio"] + &:after {
    ${tw`bg-green-500 border-green-500`}
  };

  ${props => (props.hasFocus ? tw`shadow-outline` : null)}


  ${props => (props.disabled ? tw`text-gray-600` : null)}

  &:before,
  &:after,
  ${ControlInputStyled}:checked + &:after {
    ${props => (props.disabled ? tw`bg-gray-100 border-gray-300` : null)}
  }
  ${ControlInputStyled}:checked + &:after {
    ${props => (props.disabled ? tw`opacity-50` : null)}
  }
`;

const ControlWrap = props => {
  const { id, label } = props;
  let labelProps = {};
  if (typeof props.disabled !== "undefined")
    labelProps.disabled = props.disabled;

  let wrapProps = {};
  if (typeof props.block !== "undefined") wrapProps.block = props.block;

  let inputProps = { ...props };
  if (typeof props.checked !== "undefined") {
    inputProps.checked = !!props.checked ? "checked" : "";
  }

  const [hasFocus, setHasFocus] = useState(false);
  const onFocus = function(e) {
    setHasFocus(true);
    if (typeof props.onFocus === "function")
      return props.onFocus.apply(this, arguments);
  };
  const onBlur = function(e) {
    setHasFocus(false);
    if (typeof props.onBlur === "function")
      return props.onBlur.apply(this, arguments);
  };

  return (
    <ControlWrapStyled {...wrapProps}>
      <ControlInputStyled
        id={id}
        {...inputProps}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <ControlInputLabel htmlFor={id} {...labelProps} hasFocus={hasFocus}>
        {label}
      </ControlInputLabel>
    </ControlWrapStyled>
  );
};

export const ControlCheckbox = props => {
  return <ControlWrap {...props} type="checkbox" />;
};

export const ControlRadio = props => {
  return <ControlWrap {...props} type="radio" />;
};
