import React, { forwardRef } from "react";
import tw from "tailwind.macro";
import "styled-components/macro";

const Container = forwardRef(({ children, ...props }, ref) => {
  return (
    <div
      css={`
        max-width: 1200px;
        ${tw`px-4 mx-auto`}
      `}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  );
});
export default Container;
