import React from "react";
import "styled-components/macro";
import tw from "tailwind.macro";

import Container from "../../Components/Container";
import { Label, FormHelp } from "../../Components/Form";
import { ControlRadio } from "../../Components/Form/Control";
import Row from "../../Components/Row";
import Col from "../../Components/Col";
import Button from "../../Components/Button";
import BackButton from "../../Components/BackButton";
import ControlList from "../../Components/ControlList";

const Checkup1 = ({ state, setState, nextStep }) => {
  //const [selectedType, setSelectedType] = useState(state.type);

  const onContinue = () => {
    nextStep();
  };

  const handleRadioChange = e => {
    //setSelectedType(e.target.value);
    setState({ type: e.target.value });
  };

  //lift state up
  /*useEffect(() => {
    setState({ type: selectedType });
  }, [selectedType]);*/

  const options = [
    { label: "Home owner/occupier", name: "home" },
    { label: "Renter/boarder", name: "renter" },
    { label: "Business owner", name: "business" },
    { label: "Landlord", name: "landlord" }
  ];

  const radioListItems = options.map(option => {
    return (
      <ControlRadio
        block
        label={option.label}
        name="type"
        id={`type_${option.name}`}
        value={option.name}
        onChange={handleRadioChange}
        checked={state.type === option.name}
      />
    );
  });
  return (
    <section css={tw`py-8 lg:py-16 xl:py-20`}>
      <Container>
        <fieldset>
          <Label as="legend" css={tw`w-auto border-0`}>
            Are you interested in insurance as a:
          </Label>
          <FormHelp css={tw`mt-3 mb-6`}>Choose one only</FormHelp>
          <ControlList items={radioListItems} />
        </fieldset>

        <Row css={tw`mt-12`}>
          <Col>
            <BackButton />
          </Col>
          <Col>
            <Button
              primary
              css={tw`px-6`}
              disabled={!state.type}
              onClick={onContinue}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Checkup1;
