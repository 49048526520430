import defaultConfig from "./defaultConfig";

export default function createStore(config = defaultConfig, state, setState) {
  const userConfig = Object.freeze({ ...defaultConfig, ...config });
  const { listener, logging } = userConfig;

  function updateState(storage) {
    return new Promise(resolve => {
      const newState = { ...state, ...storage };
      setState(newState);
      resolve(storage);
      listener(storage);
      window.localStorage.setItem("store", JSON.stringify(newState));
    });
  }

  let store = {
    get: (key, defaultValue) => {
      let value = state[key];
      if (value === undefined) {
        value = defaultValue;
      }
      return value;
    },
    set: (key, value) => {
      state[key] = value;
      return updateState(state);
    },
    remove: key => {
      delete state[key];
      return updateState(state);
    },
    getState: () => {
      return Object.assign({}, state);
    }
  };
  return store;
}
