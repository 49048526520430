import fetch from "unfetch";

import checkStatus from "./checkStatus";

const apiUrl = () => {
  if (process.env.REACT_APP_USE_LAMBDA === "true")
    return "/.netlify/functions/";

  return (
    (process.env.REACT_APP_SHAREPOINT_HTTPS === "true"
      ? "https://"
      : "http://") +
    process.env.REACT_APP_SHAREPOINT_URL +
    "/_api/web/"
  );
};

export default function fetchApi(url, { token = null } = {}) {
  const headers = {
    "Content-Type": "application/json"
  };
  if (token) headers["Authorization"] = "Bearer: " + token;

  console.log("fetching: ", apiUrl() + url);

  return fetch(apiUrl() + url, {
    method: "GET",
    headers
  })
    .then(checkStatus)
    .then(r => r.json());
}
