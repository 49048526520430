import React, { forwardRef } from "react";
import styled, { css } from "styled-components/macro";
import tw from "tailwind.macro";
//import PropTypes from "prop-types";
import FactSheetBlock from "../../Components/FactSheetBlock";
import Skeleton from "react-loading-skeleton";

const borderStyle = css`
  ${tw`border border-solid border-grey-400`}
`;

const ChecklistTableHeader = ({ title }) => {
  return (
    <div
      css={`
        ${borderStyle} ${tw` bg-grey-100 px-2 py-2 mb-4 lg:mb-0`}
      `}
    >
      <span css={tw`block text-lg`}>{title}</span>
    </div>
  );
};
ChecklistTableHeader.propTypes = {
  //title: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};

const ChecklistTable = props => {
  let passProps = { ...props };
  delete passProps.className;
  delete passProps.children;
  return (
    <div className={props.className}>
      <ChecklistTableHeader {...passProps} />
      <div css={``}>{props.children}</div>
    </div>
  );
};
export { ChecklistTable };

const ChecklistTableItemStyled = styled.div`
  ${borderStyle} ${tw`bg-white lg:border-t-0 mb-4 lg:mb-0 relative`}
  page-break-inside: avoid;
  ${props =>
    props.active && props.active === true
      ? `box-shadow: 0 0 15px 2px rgba(0,0,0,0.25), 0 4px 6px -2px rgba(0,0,0,0.05); border-left-width: 4px; border-left-color: #651149; @media print { border-left-width: 1px; border-left-color: #C6C6C6; }`
      : null}
    ${props => (props.active === true ? tw`bg-white z-10` : null)}
  ${props => (props.active === false ? tw`bg-grey-100 opacity-75` : null)}
  ${props => (props.hidden ? tw`hidden print:block` : null)}
`;

const ItemCopy = styled.div`
  p {
    ${tw`mb-3`}
  }
  ul {
    ${tw`mt-2 mb-3`}
  }
  *:last-child {
    ${tw`mb-0`}
  }
  .react-loading-skeleton {
    ${tw`mb-1`}
  }
`;

const ChecklistTableItem = forwardRef((props, ref) => {
  let passProps = { ...props };
  delete passProps.loading;
  delete passProps.ref;
  return (
    <ChecklistTableItemStyled
      {...passProps}
      ref={ref}
      aria-hidden={props.fadeOut || props.hidden ? "true" : null}
    >
      <div css={tw`flex flex-row flex-wrap lg:flex-no-wrap print:flex-no-wrap`}>
        <div
          css={`
            flex-grow: 1;
            ${tw`flex flex-col px-4 py-6 lg:py-4 w-full lg:w-3/4`}
          `}
        >
          <ItemCopy>
            {props.loading ? (
              <Skeleton count={3} height={20} />
            ) : (
              props.children
            )}
          </ItemCopy>
          {props.activeStep !== props.listLength ? (
            <div css={tw`text-right mt-auto`}>
              <span css={tw`text-sm text-gray-500 inline-block mt-3`}>
                Step <strong>{props.index + 1}</strong> of {props.listLength}
              </span>
            </div>
          ) : null}
        </div>
        <FactSheetBlock
          isLoading={props.isLoading || props.factSheets === "loading"}
          factSheets={props.factSheets}
          css={tw`w-full lg:w-1/4`}
        />
      </div>
      {props.fadeOut ? (
        <div
          css={`
            left: -1px;
            bottom: -1px;
            right: -1px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: linear-gradient(
                to top,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0.7)
              ),
              url("data:image/svg+xml,%3Csvg viewBox='0 0 345 133' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='172.5' y1='0' x2='172.5' y2='133' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white' stop-opacity='0'/%3E%3Cstop offset='0.5' stop-color='white'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            ${tw`absolute top-0`};
          `}
        />
      ) : null}
    </ChecklistTableItemStyled>
  );
});
export { ChecklistTableItem };
