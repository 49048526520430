//Get a checklist number (id)
// based on form results

const isInsured = (type, hasInsurance) => {
  switch (type) {
    case "renter":
      return hasInsurance.contents;
    case "home":
      return hasInsurance.home && hasInsurance.contents;
    case "business":
      return hasInsurance.business;
    case "landlord":
      return hasInsurance.rental;
    default:
      return false;
  }
};

//This whole mess looks like a good case for classes...

export default function mapChecklist({
  type = null,
  hasInsurance = null,
  isRural = null,
  postcode = null
} = {}) {
  switch (type) {
    case "renter":
      //renter/insured is 2, renter/uninsured is 1
      return isInsured(type, hasInsurance) ? 2 : 1;
    case "home":
      if (isRural) {
        return isInsured(type, hasInsurance) ? 6 : 4;
      } else {
        return isInsured(type, hasInsurance) ? 5 : 3;
      }
    case "business":
      if (isRural) {
        return isInsured(type, hasInsurance) ? 10 : 8;
      } else {
        return isInsured(type, hasInsurance) ? 9 : 7;
      }
    case "landlord":
      if (isRural) {
        return isInsured(type, hasInsurance) ? 14 : 12;
      } else {
        return isInsured(type, hasInsurance) ? 13 : 11;
      }
    default:
      return null;
  }
}
