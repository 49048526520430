import React from "react";
import tw from "tailwind.macro";
import { css } from "styled-components/macro";
import { FaFilePdf } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";

import Skeleton from "react-loading-skeleton";
import { handleOutboundLinkClick } from "../lib/analytics";

const borderStyle = css`
  ${tw`border border-solid border-grey-400`}
`;

const FactSheet = ({ isLoading, Title, PDF, Page }) => {
  return (
    <div
      css={`
        ${tw`w-full mb-8 print:mb-2`} &:last-child {
          ${tw`mb-0`}
        }
      `}
    >
      <span css={tw`block font-bold mb-4`}>
        {isLoading ? <Skeleton height={28} width={100} /> : Title}
      </span>
      <div css={tw`flex print:hidden`}>
        <div css={tw`w-1/2 border-0 border-r border-grey-400 border-solid`}>
          {isLoading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <a
              href={PDF.Url}
              css={tw`flex items-center justify-center whitespace-no-wrap underline`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleOutboundLinkClick}
            >
              View&nbsp;<strong>PDF</strong> <FaFilePdf css={tw`ml-3`} />
            </a>
          )}
        </div>
        <div css={tw`w-1/2`}>
          {isLoading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <a
              href={Page.Url}
              css={tw`flex items-center justify-center whitespace-no-wrap underline`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleOutboundLinkClick}
            >
              View&nbsp;<strong>Page</strong>{" "}
              <GoLinkExternal css={tw`ml-3 text-lg`} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const FactSheetBlock = ({ isLoading, factSheets = [], className }) => {
  return (
    <div
      css={`
        min-width: 220px;
        ${borderStyle}
        ${tw`flex flex-col justify-center items-center px-4 py-8 text-center bg-grey-100 lg:bg-transparent w-full lg:w-auto border-l-0 border-b-0 border-r-0 lg:border-t-0 lg:border-l print:border-0 print:py-2`}
        @media (min-width: 1024px) {
          min-width: 320px;
        }
        @media print {
          min-width: auto;
          max-width: 200px;
        }
      `}
      className={className}
    >
      {isLoading ? (
        <FactSheet isLoading={true} />
      ) : !factSheets.length ? null : (
        factSheets.map((factSheet, index) => (
          <FactSheet {...factSheet} key={index} />
        ))
      )}
    </div>
  );
};
export default FactSheetBlock;
