import React from "react";
import "styled-components/macro";
import tw from "tailwind.macro";
import Select from "react-select";

import Container from "../../Components/Container";
import { Label } from "../../Components/Form";
import Row from "../../Components/Row";
import Col from "../../Components/Col";
import Button from "../../Components/Button";

const Checkup3 = ({ state, prevStep, nextStep, setState, BackButton }) => {
  const onContinue = () => {
    nextStep();
  };

  const isContinueDisabled = () => {
    if (state.type !== "renter" && state.isRural === null) return true;
    if (!state.postcode) return true;
    return false;
  };

  /*const postcodeOptions = Array(7999 - 7000).map(val => {
    return { value: val + 7000, label: val + 7000 };
  });*/
  //const postcodeOptions = [{ value: 7000, label: 7000 }];
  const postcodeOptions = Array(7999 - 7000)
    .fill(null)
    .map((v, index) => {
      return { value: index + 7000, label: index + 7000 };
    });

  const selectStyles = {
    control: styles => ({
      ...styles,
      borderRadius: 0,
      border: "1px solid C6C6C6",
      borderBottomWidth: "4px"
    })
  };

  const handleSelectChange = ({ value }) => {
    setState({ postcode: value });
  };

  return (
    <section css={tw`py-8 lg:py-16 xl:py-20`}>
      <Container>
        {state.type !== "renter" ? (
          <div css={tw`mb-20`}>
            <Label as="span">Is your property in a rural area?</Label>
            <Row css={tw`mt-6`}>
              <Col>
                <Button
                  outline
                  secondary
                  css={tw`px-6`}
                  active={state.isRural === true}
                  onClick={() => setState({ isRural: true })}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  outline
                  dark
                  css={tw`px-6`}
                  active={state.isRural === false}
                  onClick={() => setState({ isRural: false })}
                >
                  No
                </Button>
              </Col>
            </Row>
          </div>
        ) : null}

        <div css={tw`mb-20`}>
          <Label>What is your postcode?</Label>
          <div css={tw`max-w-xs`}>
            <Select
              onChange={handleSelectChange}
              options={postcodeOptions}
              styles={selectStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "black"
                }
              })}
            />
          </div>
        </div>
        <Row>
          <Col>
            <BackButton />
          </Col>
          <Col>
            <Button
              primary
              css={tw`px-6`}
              onClick={onContinue}
              disabled={isContinueDisabled()}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Checkup3;
