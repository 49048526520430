import React from "react";

const AccordionContext = React.createContext();
export { AccordionContext };

const Accordion = props => {
  const { mode = "single" } = props;

  //const [toggleCallbacks, setToggleCallbacks] = useState();
  const toggleCallbacks = {};

  const toggle = (key, isExpanded) => {
    if (mode !== "single" || !isExpanded) return;
    Object.keys(toggleCallbacks).forEach(itemIndex => {
      toggleCallbacks[itemIndex](key);
    });
  };
  const externalToggle = (key, cb = null) => {
    if (cb) {
      toggleCallbacks[key] = cb;
    } else {
      delete toggleCallbacks[key];
    }
  };

  return (
    <AccordionContext.Provider value={{ toggle, externalToggle }}>
      {props.children}
    </AccordionContext.Provider>
  );
};
export default Accordion;
