//import { fromStorage } from "../store/withStore";

//
//Mainly just a convenience wrapper around ga
//
function sendGaEvent({
  eventCategory,
  eventAction,
  hitType = "event",
  eventLabel = null,
  eventValue = null,
  ...args
}) {
  const event = {
    ...args,
    hitType,
    ...(eventCategory && { eventCategory }),
    ...(eventAction && { eventAction }),
    ...(eventLabel !== null && { eventLabel }),
    ...(eventValue !== null && { eventValue })
  };

  window.ga("send", event);
}
function sendGtagEvent({
  eventCategory,
  eventAction,
  eventLabel = null,
  eventValue = null,
  ...args
}) {
  const event = {
    ...args,
    ...(eventCategory && { event_category: eventCategory }),
    ...(eventLabel !== null && { event_label: eventLabel }),
    ...(eventValue !== null && { event_value: eventValue })
  };

  window.gtag("event", eventAction, event);
}

export function sendEvent() {
  if (
    process.env?.ANALYTICS === false ||
    window?.checkup_app_data?.disable_analytics === true
  ) {
    return;
  }
  if (window?.gtag) {
    return sendGtagEvent.apply(this, arguments);
  } else if (window?.ga) {
    return sendGaEvent.apply(this, arguments);
  } else {
    console.log("Couldn't find gtag or ga");
    return;
  }
  /*const ga = window?.
  if (!ga || typeof ga !== `function`) {
    console.log("Attempted to send ga event, couldn't find ga");
    return;
  }

  const event = {
    ...args,
    hitType,
    ...(eventCategory && { eventCategory }),
    ...(eventAction && { eventAction }),
    ...(eventLabel && { eventLabel }),
    ...(eventValue && { eventValue })
  };

  if (process.env.NODE_ENV === `development`) {
    console.log("Sending GA event: ", event);
  }
  ga("send", event);*/
}

function getID() {
  if (!window?.dataLayer?.forEach) return null;
  let id = null;
  window.dataLayer.forEach(item => {
    if (item.length === 2 && item[0] === "config") {
      id = item[1];
    }
  });
  return id;
}

export function sendPageView(location) {
  if (
    process.env?.ANALYTICS === false ||
    window?.checkup_app_data?.disable_analytics === true
  ) {
    return;
  }

  if (window?.gtag) {
    if (getID())
      return window.gtag("config", getID(), {
        page_path: window.location.pathname + "#" + location.pathname //.replace(/^\//, "")
      });
  } else if (window?.ga) {
    return sendEvent({
      hitType: `pageview`,
      page: window.location.pathname + "#" + location.pathname //.replace(/^\//, "")
    });
  } else {
    console.log("Couldn't find gtag or ga");
    return;
  }
}

export function handleOutboundLinkClick(event) {
  const href = event.currentTarget.href;

  sendEvent({
    eventCategory: "Outbound link",
    eventAction: "click",
    eventLabel: href,
    transport: `beacon`
  });
}

export function setCustom(customData, gtagEvent = "event_name") {
  if (
    process.env?.ANALYTICS === false ||
    window?.checkup_app_data?.disable_analytics === true
  ) {
    return;
  }

  if (window?.gtag) {
    window.gtag("event", gtagEvent, customData);
  } else if (window?.ga) {
    window.ga("set", customData);
  } else {
    console.log("Couldn't find gtag or ga");
    return;
  }
}

/*function fakeGa() {
  return;
}*/
