import { useState, useEffect } from "react";
import to from "./to";
import getList from "./api/getList";

const useListData = (
  listName,
  listOpts = {},
  { select = null, loadOnMount = true } = {}
) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    //setData()
    setError();
    let err, _data;
    if (typeof listName === "function") {
      [err, _data] = await to(listName());
    } else {
      [err, _data] = await to(getList(listName, listOpts));
    }

    if (err) {
      setError(err);
      setIsError(true);
      setIsLoading(false);
      return;
    }

    if (select !== null) {
      if (_data?.d?.results?.length >= select) setData(_data.d.results[select]);
      else setData(null);
    } else {
      setData(_data?.d?.results);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (loadOnMount) loadData();
  }, []);

  return { data, isLoading, isError, error, loadData };
};
export default useListData;
