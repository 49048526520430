module.exports = {
  theme: {
    extend: {
      colors: {
        grey: {
          "100": "#f6f6f6",
          "200": "#f1f0f1",
          "300": "#dedbde",
          "400": "#C6C6C6"
        },
        blue: {
          "100": "#B3CEE0",
          //"200": "#4d86c7", //Light
          "200": "#80ADCB",
          "300": "#4D8CB6",
          "400": "#2673A6", //Primary
          "500": "#005A96",
          "600": "#00528E",
          "700": "#004883",
          "800": "#003F79",
          "900": "#002E68"
          //"900": "#003168" //Dark
        },
        green: {
          "100": "#b9d6d7",
          "200": "#8bbabc",
          "300": "#5c9ea1",
          "400": "#398a8d",
          "500": "#167579",
          "600": "#136d71",
          "700": "#106266",
          "800": "#0c585c",
          "900": "#064549"
        }
      },
      screens: {
        print: { raw: "print" }
      }
    }
  },
  variants: {},
  plugins: []
};
