import React from "react";
import "styled-components/macro";
import tw from "tailwind.macro";
import { ControlWrapStyled } from "./Form/Control";

const ControlList = ({ items }) => {
  return (
    <ul
      css={`
        max-width: 320px; //support ie11
        display: grid;
        grid-template-columns: minmax(0, max-content);
        ${tw`list-none p-0 m-0`}
        li ${ControlWrapStyled} {
          ${tw`block`}
        }
      `}
    >
      {items.map((item, index) => (
        <li css={tw`mb-3`} key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default ControlList;
