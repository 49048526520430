//Get multiple items by ID

import fetchApi from "./fetchApi";
const endpoint = "lists";
export default function getListItemsById(
  listTitle,
  { select = null, id = [] } = {}
) {
  let url = endpoint + "/getbytitle('" + listTitle + "')/items?";
  if (!Array.isArray(id)) id = [id];
  const filter = id
    .map(_id => {
      return `Id eq ${_id}`;
    })
    .join(" or ");
  url += "$filter=" + filter;

  if (select) url += "&";
  if (select) url += "$select=" + select.join(",");

  return fetchApi(url);
}
