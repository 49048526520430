import React from "react";
import styled, { css } from "styled-components/macro";
import tw from "tailwind.macro";

import { forwardRef } from "react";
import { AiOutlineLoading } from "react-icons/ai";

var resolveConfig = require("tailwindcss/lib/util/resolveConfig.js");

var defaultTailwindConfig = require("tailwindcss/stubs/defaultConfig.stub.js");
const config = resolveConfig.default([
  require("../tailwind.config"),
  defaultTailwindConfig
]);

const breakpointSizes = props => {
  let stylesString = ``;
  if (
    !props["size-sm"] &&
    !props["size-md"] &&
    !props["size-lg"] &&
    !props["size-xl"]
  )
    return null;
  Object.keys(config.theme.screens).forEach(screen => {
    if (!props["size-" + screen]) return;
    const size = props["size-" + screen];
    const breakpoint = config.theme.screens[screen];
    //console.log(size, breakpoint, screen);
    //stylesString = `${breakpointSizes} @media (min-width: ${size}) { color: #ff0000; } `;
    if (size === "sm") {
      stylesString = `${stylesString} @media (min-width: ${breakpoint}) { ${tw`text-sm px-2 py-1`} }`;
    }

    if (size === "xl") {
      stylesString = css`${stylesString}
        @media (min-width: ${breakpoint}) {
          ${tw`text-xl px-8 py-4`}
        }
      `;
    }
  });
  return stylesString;
};

const ButtonStyled = styled.button`
  ${tw`bg-grey-200 text-black focus:text-black text-base px-3 py-2 inline-flex cursor-pointer items-center text-center border border-transparent justify-center border-solid border-b-4 border-gray-500 min-w-0  overflow-auto hover:bg-gray-400 hover:border-gray-500 hover:text-black active:bg-gray-300 active:border-gray-500`}
  ${tw`hover:no-underline`}

  transition: 0.2s background-color ease, 0.2s color ease, 0.2s color ease;

  ${props => {
    if (props.size === "sm") return tw`text-sm px-2 py-1`;
    if (props.size === "lg") return tw`text-lg px-6 py-3`;
    if (props.size === "xl") return tw`text-2xl px-12 py-4`;
    return null;
  }}

  ${breakpointSizes}

  ${props => (props.block ? tw`w-full` : null)}

  ${props =>
    props.secondary
      ? tw`bg-blue-500 text-white border-blue-900 hover:text-white hover:bg-blue-900 hover:border-blue-900 active:bg-blue-400 active:border-blue-600 focus:text-white`
      : null}

  ${props =>
    props.primary
      ? tw`bg-green-500 text-white border-green-900 hover:text-white hover:bg-green-900 hover:border-green-900 active:bg-green-400 active:border-green-600 focus:text-white`
      : null}

  ${props =>
    props.link
      ? tw`border-transparent text-blue-500 bg-transparent underline hover:text-blue-700 hover:underline hover:bg-transparent hover:border-transparent`
      : null}

  ${props => {
    if (!props.outline) return null;
    if (props.primary)
      return tw`bg-transparent text-green-500 border-green-500 hover:text-green-500 hover:bg-grey-200 hover:border-green-700 active:bg-grey-100 focus:text-green-500`;
    if (props.secondary)
      return tw`bg-transparent text-blue-500 border-blue-500 hover:text-blue-500 hover:bg-grey-200 hover:border-blue-700 active:bg-grey-100 focus:text-blue-500`;
    return tw`border-gray-700 bg-transparent focus:text-black`;
  }}

  ${props => (props.outline && props["outline-solid"] ? tw`bg-white` : null)}

  ${props => {
    if (!props.active) return null;
    if (props.primary && props.outline)
      return tw`bg-green-500 text-white hover:bg-green-500 hover:text-white focus:text-white`;
    if (props.secondary && props.outline)
      return tw`bg-blue-500 text-white hover:bg-blue-500 hover:text-white focus:text-white`;
    if (props.outline)
      return tw`bg-black text-white hover:bg-black hover:text-white focus:text-white`;
    return null;
  }}

  ${props =>
    props.disabled || props.loading ? tw`opacity-50 cursor-default` : null}
  
  svg {
    ${tw`text-xl`}
    ${props => (props.loading ? `animation: 1s spin infinite;` : null)}
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    95% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = forwardRef((props, ref) => {
  let passProps = { ...props };

  const propList = [
    "primary",
    "secondary",
    "outline",
    "block",
    "link",
    "outline-solid"
  ];

  if (typeof passProps.active !== "undefined") {
    if (passProps.active === true) passProps.active = "true";
    else delete passProps.active;
  }

  propList.map(propName => {
    if (typeof props[propName] !== "undefined") {
      passProps[propName] = "true";
    }
    return null;
  });

  passProps.loading =
    typeof props["loading"] !== "undefined" && props.loading
      ? "true"
      : undefined;

  if (props.loading) {
    passProps.children = (
      <>
        {props.loading.length ? <span>{props.loading}</span> : null}
        <span css={props.loading.length ? tw`ml-3` : tw`px-6`}>
          <AiOutlineLoading />
        </span>
      </>
    );
  }

  if (props.icon) {
    passProps.children = (
      <>
        <span css={tw`mr-3`}>{props.icon}</span>{" "}
        <span css={tw`mr-2`}>{props.children}</span>
      </>
    );
  }
  if (props.iconAfter) {
    passProps.children = (
      <>
        <span css={tw`ml-2`}>{props.children}</span>{" "}
        <span css={tw`ml-3`}>{props.iconAfter}</span>
      </>
    );
  }

  return (
    <ButtonStyled as={passProps.tag} {...passProps} ref={ref}>
      {passProps.children}
    </ButtonStyled>
  );
});
export default Button;
