import fetchApi from "./fetchApi";
const endpoint = "lists";
export default function getList(
  listTitle,
  { select = null, item = null, filter = null } = {}
) {
  let url = endpoint + "/getbytitle('" + listTitle + "')/items";
  if (item) url += "(" + item + ")";
  if (filter || select) url += "?";
  if (filter) url += "$filter=" + filter;
  if (filter && select) url += "&";
  if (select) url += "$select=" + select.join(",");
  return fetchApi(url);
}
