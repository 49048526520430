import React from "react";
import { withRouter } from "react-router-dom";
import Button from "./Button";
import "styled-components/macro";
import tw from "tailwind.macro";
import { MdChevronLeft } from "react-icons/md";

const BackButton = withRouter(({ history }) => {
  return (
    <Button css={tw`px-6`} onClick={history.goBack} icon={<MdChevronLeft />}>
      Back
    </Button>
  );
});
export default BackButton;
